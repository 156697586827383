import React, { useState } from "react";
import { useEthers } from "@usedapp/core";
import { useSpring, animated } from "react-spring";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { walletconnect, walletlink } from "../util/connectors";
import metamaskLogo from "../images/logo-metamask.png";
import wallectConnectLogo from "../images/logo-walletconnect.svg";
import coinbaseLogo from "../images/coinbase-wallet-logo.png";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));

const Jiggle = ({ children, ...props }) => {
  const Animation = useSpring({
    loop: { reverse: true },
    config: { tension: 200, friction: 5, clamp: false, duration: 500 },
    from: { scaleX: 1, scaleY: 1, y: 0 },
    to: { scaleX: 1.1, scaleY: 0.9, y: 3 },
    reset: true,
  });
  return (
    <animated.span style={Animation} {...props}>
      {children}
    </animated.span>
  );
};

function WalletConnect() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { activateBrowserWallet, activate, account } = useEthers();

  function handleConnect() {
    activateBrowserWallet();
    setShow(false);
  }

  const handleWalletConnector = (connector) => {
    activate(connector);
  };

  if (account) {
    return (
      <>
      </>
    );
  } else {
    return (
      <>
        <Jiggle>
          <Button size="large" variant="contained" onClick={handleShow}>
            Connect
          </Button>
        </Jiggle>
        <Modal open={show} onClose={handleClose}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Item>
                  <Button
                    sx={{ display: "flex", flexDirection: "column" }}
                    variant="outline-secondary"
                    onClick={handleConnect}
                  >
                    <Box
                      sx={{ width: "50%", height: "50%" }}
                      component="img"
                      src={metamaskLogo}
                    />
                    <Typography variant="h3" component="div">
                      Metamask
                    </Typography>
                  </Button>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <Button
                    variant="outline-secondary"
                    sx={{ display: "flex", flexDirection: "column" }}
                    onClick={() => {
                      handleWalletConnector(walletlink);
                    }}
                  >
                    <Box
                      sx={{ width: "50%", height: "50%" }}
                      component="img"
                      src={coinbaseLogo}
                    />
                    <Typography variant="h3" component="div">
                      Coinbase
                    </Typography>
                  </Button>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <Button
                    sx={{ display: "flex", flexDirection: "column" }}
                    variant="outline-secondary"
                    onClick={() => {
                      handleWalletConnector(walletconnect);
                    }}
                  >
                    <Box
                      sx={{ width: "50%", height: "50%" }}
                      component="img"
                      src={wallectConnectLogo}
                    />
                    <Typography variant="h3" component="div">
                      Wallet Connect
                    </Typography>
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    );
  }
}

export default WalletConnect;
