import { ethers } from "ethers";
import { useCall, useCalls } from "@usedapp/core";
import { sadDinosAddress } from "../contract";
import sadDinosAbi from "../abi/SadDinos.json";

export const SadDinosContract = new ethers.Contract(sadDinosAddress, sadDinosAbi.abi);

export function useAccountMinted(account){
    const call = account && {
        contract: SadDinosContract,
        method: "numberMinted",
        args: [String(account)]
    };
    const {value, error} = useCall(call) ?? {};
    if(error) {
        console.error(error.message)
        return undefined
    }
    if(value === undefined){
        return 0;
    }
    return parseInt(value[0]);
}

export function useFreeMinted(account){
    const call = account && {
        contract: SadDinosContract,
        method: "numberFreeMinted",
        args: [String(account)]
    };
    const {value, error} = useCall(call) ?? {};
    if(error) {
        console.error(error.message)
        return undefined
    }
    if(value === undefined){
        return 0;
    }
    return parseInt(value[0]);
}

export function useContractValues(){
    const calls = [{
        contract: SadDinosContract,
        method: "totalSupply",
        args: []
    },
    {
        contract: SadDinosContract,
        method: "MAX_SUPPLY",
        args: []
    },
    {
        contract: SadDinosContract,
        method: "maxPerAddress",
        args: []
    },  
    {
        contract: SadDinosContract,
        method: "maxPerTx",
        args: []
    },
    {
        contract: SadDinosContract,
        method: "freeMax",
        args: []
    },
    {
        contract: SadDinosContract,
        method: "mintPrice",
        args: []
    },{
        contract: SadDinosContract,
        method: "isPublicSaleActive",
        args: []
    }];
       
    const results = useCalls(calls) ?? [];
    results.forEach((result) => {
        if(result && result.error) {
          console.error(`${result.error.message}`)
        }
    });
    if(results[0] === undefined){
        return {
            totalSupply: undefined,
            maxSupply: undefined,
            maxPerAddress: undefined,
            maxFree: undefined,
            price: undefined      
        }
    }
    return {
        totalSupply: parseInt(results[0].value[0]),
        maxSupply: parseInt(results[1].value[0]),
        maxPerAddress: parseInt(results[2].value[0]),
        maxPerTx: parseInt(results[3].value[0]),
        maxFree: parseInt(results[4].value[0]),
        price: results[5].value[0],
        isPublicSaleActive: results[6].value[0],
    };
}