import { useRef } from "react";
import { useSpring, animated } from "react-spring";
import { config } from "react-spring";

import { FaTwitter } from "react-icons/fa";

import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Background from "../images/dino_background_dark.png";
import Dino1 from "../images/dino1.png";
import Dino1rev from "../images/flower.png";
import opensealogo from "../images/opensea.svg";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";

import MintPanel from "../components/MintPanel";
import TeamPanel from "../components/TeamPanel";

import { getOpenseaURL } from "../util/config";

const FadeInText = ({ children, ...props }) => {
  const Animation = (i) =>
    useSpring({
      opacity: 1,
      from: { opacity: 0 },
      delay: Math.random() * 350,
    });
  return children.split("").map((item, index) => (
    <animated.span key={index} style={Animation(index)} {...props}>
      {item}
    </animated.span>
  ));
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, .95)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  width: "50%",
  marginBottom: "10px",
  color: theme.palette.text.secondary,
  paddingLeft: "10px",
  paddingRight: "10px",
}));

function DesktopFront() {
  const parallax = useRef();

  const handleScrollTo = (page) => {
    parallax.current.scrollTo(page);
  };

  const openseaURL = getOpenseaURL();

  return (
    <Parallax
      ref={parallax}
      config={config.slow}
      pages={3}
      horizontal={true}
      enabled={false}
    >
      <ParallaxLayer
        offset={0}
        speed={0}
        factor={3}
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "auto",
        }}
      />

      <ParallaxLayer
        offset={1.0}
        speed={0.5}
        factor={1}
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
        }}
      >
        <Box
          className="dino"
          component="img"
          align="bottom"
          sx={{
            height: "33%",
          }}
          src={Dino1}
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={1}
        speed={-0.5}
        factor={1}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "end",
        }}
      >
        <Box
          className="dino"
          component="img"
          align="bottom"
          sx={{
            height: "33%",
          }}
          src={Dino1rev}
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={0}
        speed={0}
        factor={1}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          className="glowing"
          variant="h1"
          component="div"
          gutterBottom
        >
          <FadeInText>Sad Dinos</FadeInText>
        </Typography>
        <Item>
          <Typography align="center" variant="h5" component="div" gutterBottom>
            The Dinos were living a happy life in Dinoland. But suddenly, BAM!
            They heard a loud explosion. The Dinos rushed outside to find all
            their friends dead. Now, the survivors always have a sad face
            because of this traumatic event.
          </Typography>
        </Item>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            handleScrollTo(1);
          }}
        >
          Enter
        </Button>
        <Box sx={{ paddingTop: "10px" }}>
          <Link
            href="https://twitter.com/SadDinosNFT"
            underline="none"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter size={42} color="#000" />
          </Link>
          <Link
            href={openseaURL}
            underline="none"
            target="_blank"
            rel="noreferrer"
            sx={{ paddingLeft: "10px" }}
          >
            <Box component="img" align="bottom" src={opensealogo} />
          </Link>
        </Box>
      </ParallaxLayer>


      <ParallaxLayer
        offset={1}
        speed={0}
        factor={1}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          className="glowing"
          sx={{ cursor: "pointer", paddingBottom: "50px" }}
          onClick={() => {
            handleScrollTo(0);
          }}
          component="span"
          variant="h1"
          gutterBottom
        >
          ← Leave
        </Typography>
        <MintPanel />
        <Typography
          className="glowing"
          sx={{ cursor: "pointer", paddingTop: "50px" }}
          onClick={() => {
            handleScrollTo(3);
          }}
          component="span"
          variant="h1"
          gutterBottom
        >
          Team →
        </Typography>
      </ParallaxLayer>

      <ParallaxLayer
        offset={2}
        speed={0.75}
        style={{ backgroundColor: "#ff6d6d" }}
      />
      <ParallaxLayer
        offset={2}
        speed={0.5}
        factor={1}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          className="glowing"
          sx={{ cursor: "pointer", paddingBottom: "50px" }}
          onClick={() => {
            handleScrollTo(1);
          }}
          component="span"
          variant="h1"
          gutterBottom
        >
          ← Mint
        </Typography>
        <TeamPanel />
      </ParallaxLayer>
    </Parallax>
  );
}

export default DesktopFront;
