import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

import DesktopFront from "./pages/DesktopFront";
import MobileFront from "./pages/MobileFront";

const theme = createTheme({
  typography: {
    fontFamily: ["Finger Paint", "cursive"].join(","),
    h1:{
      '@media (max-width:900px)': {
        fontSize: '2.4rem',
      },
    },
    h2:{
      '@media (max-width:900px)': {
        fontSize: '2.0rem',
      },
    },
    h3:{
      '@media (max-width:900px)': {
        fontSize: '1.0rem',
      },
    },
    h5:{
      '@media (max-width:900px)': {
        fontSize: '1.0rem',
      },
    }
  },
});

function App() {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <ThemeProvider theme={theme}>
      {matches ? (
        <DesktopFront />
      ) : (
        <MobileFront />
      )}

    </ThemeProvider>
  );
}

export default App;
