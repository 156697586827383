import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { INFURA_PROJECT_ID, active_url } from './config';

const RPC_URLS = {
  1: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
  4: `https://rinkeby.infura.io/v3/${INFURA_PROJECT_ID}`,
};

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1], 4: RPC_URLS[4] },
  infuraId: INFURA_PROJECT_ID,
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
    url: active_url,
    appName: "Sad Dinos"
});