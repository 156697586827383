import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { ethers } from "ethers";
import { useEthers, useContractFunction } from "@usedapp/core";

import WalletConnect from "./WalletConnect";

import {
  SadDinosContract,
  useContractValues,
  useAccountMinted,
  useFreeMinted,
} from "../util/interact";

// import { getEtherscanUrl, getOpenseaURL } from "../util/config";
import { truncateEthAddress } from "../util/truncate";

const Panel = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, .95)",
  ...theme.typography.body2,
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  padding: "30px",
}));

const Bounce = ({ children, ...props }) => {
  const Animation = useSpring({
    loop: { reverse: true },
    config: { tension: 200, friction: 5, clamp: false, duration: 500 },
    from: { y: 0 },
    to: { y: 15 },
    reset: true,
  });
  return (
    <animated.span style={Animation} {...props}>
      {children}
    </animated.span>
  );
};

function MintPanel() {
  const { account, deactivate } = useEthers();
  // const etherScanURL = getEtherscanUrl();
  // const openSeaURL = getOpenseaURL();
  const {
    totalSupply,
    maxSupply,
    maxPerAddress,
    maxPerTx,
    maxFree,
    price,
    isPublicSaleActive,
  } = useContractValues();
  const mintedByAccount = useAccountMinted(account);
  const mintedFreeByAccount = useFreeMinted(account);
  const [isDisabled, setDisabled] = useState(false);
  const [mintText, setMintText] = useState("Mint");
  const [mintAmount, setMintAmount] = useState(1);
  
  const increaseMintAmount = () => {
    if ((mintAmount < maxPerTx) && ((mintedByAccount + mintAmount) < maxPerAddress)) {
      setMintAmount((mintAmount) => mintAmount + 1);
    }
  };

  const decreaseMintAmount = () => {
    if (mintAmount > 1) {
      setMintAmount((mintAmount) => mintAmount - 1);
    }
  };

  const mintFunction = useContractFunction(SadDinosContract, "mint", {
    transactionName: "mint"
  });

  const getPrice = (num) => {
    var payFor = num;
    if (mintedFreeByAccount < maxFree) {
      const freeAllowed = maxFree - mintedFreeByAccount;
      if (freeAllowed > num) {
        payFor = 0;
      } else {
        payFor = payFor - freeAllowed;
      }
    }
    return (payFor * parseFloat(price))/1e18;
  };

  const handleMint = async () => {
    setDisabled(true);
    await mintFunction.send(mintAmount, {
      value: ethers.utils.parseEther(getPrice(mintAmount).toString()),
      gasLimit: 300000
    });
    setDisabled(false);
  };

  useEffect(()=>{
    if(mintedByAccount >= maxPerAddress){
      setDisabled(true);
      setMintText("Maxed");
      setMintAmount(0);
    }
  },[mintedByAccount,maxPerAddress])
  const mintCounter = (
    <>
      <ButtonGroup aria-label="small outlined button group">
        <Button onClick={decreaseMintAmount}>-</Button>
        <Button >{mintAmount}</Button>
        <Button onClick={increaseMintAmount}>+</Button>
        <Button onClick={handleMint} disabled={isDisabled} variant="contained">
                {mintText}
        </Button>
        <Button >{getPrice(mintAmount)} Ξ</Button>
      </ButtonGroup>
    </>
  );



  if (!account) {
    return (
      <>
        <Bounce>
          <Typography
            className="glowing"
            variant="h1"
            component="div"
            align="center"
            gutterBottom
          >
            Mint Here <br /> ↓
          </Typography>
        </Bounce>
        <WalletConnect />
      </>
    );
  } else {
    return (
      <>
        <Panel>
          <Typography variant="h2" component="div" gutterBottom>
            Mint Sad Dino
          </Typography>
          {!isPublicSaleActive ? (
            <>
              <Typography variant="h3" component="div" gutterBottom>
                Coming soon...
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h2" component="div" gutterBottom>
                {totalSupply} / {maxSupply}
              </Typography>
              <Typography variant="h3" component="div" gutterBottom>
                {maxFree} free, {parseFloat(price) / 1e18} eth additional
              </Typography>
              <Typography variant="h5" component="div" gutterBottom>
                ({maxPerAddress} total per wallet)
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <Typography variant="h5" component="div" gutterBottom>
                  Connected: {truncateEthAddress(account)}
                </Typography>
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={deactivate}
                  component="span"
                  variant="h5"
                  gutterBottom
                >
                  &nbsp; &nbsp; Disconnect
                </Typography>
              </Box>
              <Typography variant="h5" component="div" gutterBottom>
                You minted {mintedByAccount } (
                {mintedFreeByAccount} free)
              </Typography>
              {mintCounter}

              <Typography variant="h5" component="div" gutterBottom>
                
              </Typography>
            </>
          )}
        </Panel>
      </>
    );
  }
}

export default MintPanel;
