import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import wings from "../images/Wings.png";
import party from "../images/Partyhat.png"
import glasses from "../images/Glasses.png"
import { Typography } from "@mui/material";

function TeamPanel() {
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        textAlign="center"
      >
        <Grid item xs={4}>
          <Box
            className="dino"
            component="img"
            align="bottom"
            sx={{
              background: "#fff",
              border: 5,
              borderRadius: 16,
              width: "75%",
            }}
            src={wings}
          />
          <Typography
            className="glowing"
            variant="h3"
            component="div"
            gutterBottom
          >
            <Link
              sx={{ color: "inherit" }}
              href="https://twitter.com/NFTKade"
              underline="none"
              target="_blank"
              rel="noreferrer"
            >
              Dino Artist
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box
            className="dino"
            component="img"
            align="bottom"
            sx={{
              background: "#fff",
              border: 5,
              borderRadius: 16,
              width: "75%",
            }}
            src={party}
          />
          <Typography
            className="glowing"
            variant="h3"
            component="div"
            gutterBottom
          >
              ????????
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box
            className="dino"
            component="img"
            align="bottom"
            sx={{
              background: "#fff",
              border: 5,
              borderRadius: 16,
              width: "75%",
            }}
            src={glasses}
          />
          <Typography
            className="glowing"
            variant="h3"
            component="div"
            gutterBottom
          >
              ????????
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export function TeamPanelMobile() {
    return (
      <>
        <Grid
          container
          spacing={2}
          direction="column"
          textAlign="center"
        >
          <Grid item xs={4}>
            <Box
              className="dino"
              component="img"
              align="bottom"
              sx={{
                background: "#fff",
                border: 5,
                borderRadius: 16,
                width: "25%",
              }}
              src={wings}
            />
            <Typography
              className="glowing"
              variant="h3"
              component="div"
              gutterBottom
            >
              <Link
                sx={{ color: "inherit" }}
                href="https://twitter.com/NFTKade"
                underline="none"
                target="_blank"
                rel="noreferrer"
              >
                Dino Artist
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box
              className="dino"
              component="img"
              align="bottom"
              sx={{
                background: "#fff",
                border: 5,
                borderRadius: 16,
                width: "25%",
              }}
              src={party}
            />
            <Typography
              className="glowing"
              variant="h3"
              component="div"
              gutterBottom
            >
                ????????
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box
              className="dino"
              component="img"
              align="bottom"
              sx={{
                background: "#fff",
                border: 5,
                borderRadius: 16,
                width: "25%",
              }}
              src={glasses}
            />
            <Typography
              className="glowing"
              variant="h3"
              component="div"
              gutterBottom
            >
                ????????
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

export default TeamPanel;
