import {Rinkeby, Mainnet} from '@usedapp/core';

export const INFURA_PROJECT_ID="af2da40997b1475c8b0726b0db668dd5";

const is_rinkeby = process.env.REACT_APP_CHAIN_ID === "4";

const mainnet_url = 'https://mainnet.infura.io/v3/af2da40997b1475c8b0726b0db668dd5';
const rinkeby_url = 'https://rinkeby.infura.io/v3/af2da40997b1475c8b0726b0db668dd5';

export const active_url = is_rinkeby ? rinkeby_url : mainnet_url;

export const getDappConfig = () => {
    
    if(is_rinkeby){
        return{
            readOnlyChainId: Rinkeby.chainId,
            readOnlyUrls:{
                [Rinkeby.chainId]: rinkeby_url,
            },
            multicallAddresses: {
                [Rinkeby.chainId]: Rinkeby.multicallAddress,
            },
            autoConnect: false
        }
    }
    return {
        readOnlyChainId: Mainnet.chainId,
        readOnlyUrls:{
            [Mainnet.chainId]: mainnet_url,
        },
        multicallAddresses: {
            [Mainnet.chainId]: Mainnet.multicallAddress,
        },
        autoConnect: false
    }
}

export const getEtherscanUrl = () => {
    if(is_rinkeby){
        return "https://rinkeby.etherscan.io/";
    }
    return "https://etherscan.io/"
}

export const getOpenseaURL = () => {
    if(is_rinkeby){
        return "https://testnets.opensea.io/collection/saddinos";
    }
    return "https://opensea.io/collection/saddinos"
}

export const getLooksRareURL = () => {
    return "https://looksrare.org/collections/0x0eeD0a2b4Df338Fc1bCA6115E4100592632d14cd?queryID=6b924a4b48e62c1a0fbf82acf0bf70b0&queryIndex=prod_tokens"
}